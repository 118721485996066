<template>
  <div class="bbb-pages">
    <div class="bbb-landing">
      <router-link :to="url_back" class="close-button">
        <img src="/static/img/big-bang-boom/close-bbb-store.svg" width="100" @click="postTracker('close')" />
      </router-link>

      <img src="/static/img/soco-beauty-star/title-sbs-store.png" class="title" width="580" height="170" />

      <h2 v-if="isVnSource" class="text-greeting" :class="{ vn: isVnSource }">Chào, {{ name }}</h2>
      <h2 v-else class="text-greeting">Hi, {{ name }}</h2>

      <img :src="image" width="400" height="400" style="border-radius: 100%" /><br />

      <div class="sbs-card">
        <!-- <p>
          Banyak jalan menuju <s>Roma</s> Hadiah.<br />
          Pilih yang mana, Bestie?
        </p> -->
        <p>
          Main-main berhadiah, kapan lagi Bestie?<br />
          Yuk, main Beauty ATM & dapetin reward-nya!
        </p>
      </div>

      <button class="sbs-card-btn" type="button" :disabled="loadingBeautyAtmData" @click="goToGame">
        <h1>
          <b>
            MAIN GAME:<br />
            BEAUTY ATM
          </b>
        </h1>
        <img src="/static/img/soco-beauty-star/game-store.png" width="200" height="190" />
        <p>
          Freebies & voucher <br />
          diskon menarik <br />
          menantimu
        </p>
      </button>

      <!-- <button class="sbs-card-btn">
        <h1>
          <b> TUKAR SOCO POINT </b>
        </h1>
        <img src="/static/img/soco-beauty-star/soco-point-store.png" width="220" height="190" />
        <p>
          Dapatkan potongan <br />
          harga untuk beauties <br />
          pilihanmu
        </p>
      </button> -->
    </div>
  </div>
</template>

<script>
import VendingMachineTracker from '@/mixins/vending-machine-tracker';
import autotimeout from '@/mixins/auto-timeout';
import { userActions } from '@/helpers/user-actions';

export default {
  name: 'SocoBeautyStarLanding',
  mixins: [VendingMachineTracker, autotimeout],
  computed: {
    name() {
      if (this.$SO.size(this.$store.state.auth.userProfile)) {
        return this.$store.state.auth.userProfile.first_name;
      } else {
        return '';
      }
    },
    image() {
      if (this.$SO.size(this.$store.state.auth.userProfile)) {
        return this.$store.state.auth.userProfile.image;
      } else {
        return '';
      }
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    url_next() {
      if (this.$route.query.isvn) {
        return `/vending-machine-vn/pimple-pop/how-to-play?isvn=true`;
      } else {
        return `/vending-machine/pimple-pop/how-to-play`;
      }
    },
    url_back() {
      if (this.$route.query.isvn) {
        return `/vending-machine-vn/home?isvn=true`;
      } else {
        const path = Cookies.get('vending_machine_device') == 'true' ? 'home' : 'login';
        return '/vending-machine/' + path;
      }
    },
    beautyAtmData() {
      return this.$store.state.beautyAtmData;
    },
    loadingBeautyAtmData() {
      return this.$store.state.loadingBeautyAtmData;
    },
  },
  created() {
    this.getBeautyAtmData();
  },
  mounted() {
    userActions(this);
  },
  methods: {
    getBeautyAtmData() {
      this.$store.dispatch('getBeautyAtmData');
    },
    goToGame() {
      if ((!this.beautyAtmData && !this.loadingBeautyAtmData) || this.loadingBeautyAtmData) {
        this.$toasted.global.error(`There's no rule active`);
        return;
      }
      this.$router.push(this.url_next);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  position: fixed;
  top: 50px;
  right: 50px;
}
.bbb-landing {
  min-height: 100vh;
  padding: 134px 24px 0;
  text-align: center;
  background: #000 url('/static/img/soco-beauty-star/bg-sbs-store.png') no-repeat center;
  background-size: cover;
}
.text-greeting {
  font: normal 64px/78px 'brandontext-bold', sans-serif;
  margin: 0 0 100px;
  color: #b32656;
  text-transform: capitalize;
}
.vn {
  font-family: 'SVN-brandontext-regular', sans-serif;
}
.title {
  margin: 0 auto 60px;
  display: block;
}
.play-text {
  margin: 0 auto 30px;
  display: block;
}

.sbs-card {
  font: normal 44px/58px 'brandontext-bold', sans-serif;
  margin: 80px 0 50px 50px;
  padding: 20px;
  border-radius: 40px;
  background: #ce3377;
  color: #fff;
  box-shadow: 3px 12px 12px rgba(48, 0, 10, 0.5);
  width: 90%;
}

.sbs-card-btn {
  height: 520px;
  width: 340px;
  padding: 10px 10px 10px 20px;
  margin: 0 50px 0 50px;
  text-align: center;
  background: url('/static/img/soco-beauty-star/card-btn-sbs-store.png');
  background-size: cover;
  border: 0;
  border-radius: 20px;
  font: normal 30px/36px 'brandontext-bold', sans-serif;
  color: #b32656;
  box-shadow: 3px 10px 10px rgba(48, 0, 10, 0.6);

  &:active {
    color: #000;
    box-shadow: 3px 10px 10px rgba(48, 0, 10, 0.2);
  }
}
</style>
